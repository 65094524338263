.admin-number-picker {
  &:hover {
    & input[type="text"] {
      @apply bg-slate-50;
    }
  }

  &:focus-within {
    & input[type="text"] {
      @apply bg-slate-25;
    }
  }

  & input[type="text"]:focus {
    outline: none !important;
    box-shadow: none;
  }
}
